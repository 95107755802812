<template>
  <section>
    <v-row justify="start" class="px-10 mt-10">
      <v-col cols="6">
        <v-card>
          <div class="card-header-padding">
            <div class="d-flex align-center">
              <div v-if="daalgavar">
                Даалгавар:
                <h3 class="text-typo mb-0">
                  <span style="color: green"></span>{{ daalgavar.title }}
                </h3>

                <small
                  style="cursor: pointer"
                  @click.stop="_detail(daalgavar.videoUrl)"
                  small
                  class="
                    border-radius-sm
                    text-xxs text-white
                    me-1
                    shadow-none
                    font-weight-bold
                    px-3
                    py-1
                    btn-warning
                    bg-gradient-warning
                  "
                  elevation="0"
                  :ripple="false"
                  v-if="daalgavar.videoUrl && isYoutube(daalgavar.videoUrl)"
                  title="Контентоо шалгаx"
                  >Даалгавар</small
                >

                <small
                  style="cursor: pointer; background: #eef4fa; color: #007aff"
                  @click.stop="detail(daalgavar.slideUrl)"
                  small
                  class="
                    border-radius-sm
                    text-xxs
                    me-1
                    shadow-none
                    font-weight-bold
                    px-3
                    py-1
                  "
                  elevation="0"
                  :ripple="false"
                  v-if="daalgavar.slideUrl"
                  title="Контентоо шалгаx"
                  >Даалгавар</small
                >
              </div>
            </div>
          </div>

          <!-- <p v-for="(student, sindex) in users2" :key="sindex">{{student.firstName}}</p> -->
          <div class="card-header-padding" v-if="!loading">
            <v-data-table
              v-if="users2"
              :headers="headers2"
              :items="users2"
              hide-default-header
              hide-default-footer
              :items-per-page="-1"
            >
              <template v-slot:header="{ props: { headers } }">
                <thead>
                  <tr>
                    <th
                      class="text-center"
                      v-for="(h, hIndex) in headers"
                      :key="hIndex"
                    >
                      <span>{{ h.text }}</span>
                    </th>
                  </tr>
                </thead>
              </template>
              <template slot="item" slot-scope="props">
                <tr
                  style="cursor: pointer"
                  @click="_selectStudent(props.item)"
                  :style="
                    selectedStudent && selectedStudent.id == props.item.id
                      ? 'background:#ececec'
                      : ''
                  "
                >
                  <td class="text-center" width="1%">{{ props.item.index }}</td>
                  <td
                    v-for="(name, index) in bodyNames"
                    :key="'td' + index"
                    class="pl-2"
                  >
                    {{ props.item[name] }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
      <v-col cols="6" v-if="selectedStudent">
        <v-card class="pa-4">
          <v-card-actions>
            <h1 class="pb-4">{{ selectedStudent.firstName }}</h1>
            <v-spacer></v-spacer>
            <v-btn
              @click="_scoring"
              class="bg-gradient-success"
              dark
              :ripple="false"
              elevation="0"
              >Дүгнэx</v-btn
            >
          </v-card-actions>

          <div
            v-if="selectedStudent.attachment"
            id="iframecontainer"
            v-html="selectedStudent.attachment"
          ></div>
          <div v-if="selectedStudent.description">
            <p>
              {{ selectedStudent.description }}
            </p>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="scoringDialog" max-width="500">
      <v-card class="px-6 py-6">
        <v-card-text v-if="selectedStudent">
          <h2>Үнэлгээ оруулаx</h2>
          <v-text-field
            type="number"
            v-model.number="selectedStudent.score"
            autofocus
            label="Дүнгээ оруулаx"
          >
          </v-text-field>
        </v-card-text>
        <v-card-text v-if="selectedStudent">
          <v-textarea
            v-model="selectedStudent.description"
            hide-details
            outlined
            label="Тайлбар зөвлөгөө бичиx"
            color="rgba(0,0,0,.6)"
            value="Say a few words about who you are or what you're working on."
            class="
              font-size-input
              border
              text-light-input
              border-radius-md
              mt-2
            "
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <!-- <v-btn></v-btn> -->
          <v-spacer></v-spacer>
          <v-btn text @click="_cancelScore">Цуцлаx</v-btn>
          <v-btn class="bg-gradient-success" @click="_saveScore" dark>
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showSlide" max-width="800">
      <!-- <iframe
        width="100%"
        height="800"
        v-if="
          slideId != null && slideId != undefined
        "
        v-bind:src="slideId"
        frameborder="0"
        allowfullscreen
      ></iframe> -->

      <div id="iframecontainer2" v-html="slideId"></div>
    </v-dialog>
    <v-dialog v-model="showVideo">
      <iframe
        width="100%"
        height="800"
        v-if="
          videoId != null &&
          videoId != '' &&
          videoId.toLowerCase().indexOf('youtube') != -1
        "
        v-bind:src="getYoutubeIframe(videoId)"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </v-dialog>
  </section>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";

export default {
  data() {
    return {
      daalgavar: null,
      headerNames: null,
      students: null,
      bodyNames: ["firstName", "score", "hwDone", "turnedInAt"],
      selectedStudent: null,
      scoringDialog: false,

      addNewItem: false,
      videoId: null,
      showVideo: false,

      showSlide: null,
      slideId: false,
      loading: false,
    };
  },
  computed: {
    ...sync("*"),
    users2() {
      var list = [];
      if (this.students != null) {
        var counter = 0;
        for (var item of this.students) {
          counter++;
          item.index = counter;
          list.push(item);
        }
      }
      return list;
    },

    headers2() {
      return this.headerNames;
    },
  },
  props: {
    classGroupPath: {
      type: String,
      required: true,
    },
    daalgavarPath: {
      type: String,
      required: true,
    },
  },
  created() {
    this.headerNames = [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "firstName",
        fixed: true,
      },
      {
        text: "Score",
        align: "start",
        sortable: true,
        value: "score",
        fixed: true,
      },
      {
        text: "Submitted",
        align: "start",
        sortable: true,
        value: "hwDone",
        fixed: true,
      },
      {
        text: "Turned In At",
        align: "start",
        sortable: true,
        value: "turnedInAt",
        fixed: true,
      },
    ];
    this.loading = true;
    fb.db
      .doc(this.daalgavarPath)
      .get()
      .then((doc) => {
        let daalgavar = doc.data();
        daalgavar.id = doc.id;
        daalgavar.ref = doc.ref;
        this.daalgavar = daalgavar;
      })
      .then(() => {
        this.userData.school.ref
          .collection("students")
          .where("classGroup-2022", "==", fb.db.doc(this.classGroupPath))
          .onSnapshot((docs) => {
            this.students = [];
            docs.forEach(async (doc) => {
              let student = doc.data();
              student.id = doc.id;
              student.ref = doc.ref;
              student.score = null;
              await this.daalgavar.ref
                .collection("assignments")
                .doc(student.id)
                .onSnapshot((xdoc) => {
                  if (xdoc.exists) {
                    student.hwDone = "Хийсэн";
                    console.log(xdoc.data());
                    student.attachmentRef = xdoc.ref;
                    if (xdoc.data().description)
                      student.description = xdoc.data().description;
                    if (xdoc.data().score) student.score = xdoc.data().score;

                    student.turnedInAt = xdoc
                      .data()
                      .turnedInAt.toDate()
                      .toLocaleString();
                    student.attachment = xdoc.data().attachment;
                  } else {
                    student.hwDone = "Хийгээгүй";
                  }
                });
              this.students.push(student);
            });
            this.loading = false;
          });
      });
  },
  methods: {
    _selectStudent(student) {
      this.selectedStudent = student;
    },
    _scoring() {
      this.scoringDialog = true;
    },
    _saveScore() {
      if (this.selectedStudent.score) {
        console.log(this.selectedStudent);
        if (this.selectedStudent.attachmentRef) {
          this.selectedStudent.attachmentRef
            .set(
              {
                score: this.selectedStudent.score,
                teacherComment: this.selectedStudent.description
                  ? this.selectedStudent.description
                  : null,
                scoredAt: new Date(),
              },
              { merge: true }
            )
            .then(() => {
              this.scoringDialog = false;
              console.log(this.selectedStudent.attachmentRef);
            });
        }
      }
    },
    _cancelScore() {
      this.scoringDialog = false;
    },

    _detail(url) {
      this.addNewItem = false;
      this.videoId = url;
      this.showVideo = !this.showVideo;
    },
    detail(url) {
      this.addNewItem = false;
      this.slideId = url;
      this.showSlide = !this.showSlide;
    },
    isYoutube(videoUrl) {
      return videoUrl.includes("youtube");
    },
    getYoutubeIframe(url) {
      if (
        url != null &&
        url != "" &&
        url.toLowerCase().indexOf("youtube") != -1
      ) {
        var id = this.getYoutubeId(url);
        return "https://www.youtube.com/embed/" + id;
      }
      return null;
    },
    getYoutubeId(url) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11 ? match[2] : null;
    },
  },
};
</script>
<style>
#iframecontainer iframe {
  width: 100% !important;
}
#iframecontainer2 iframe {
  width: 100% !important;
}
</style>
